import { Container, Heading, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DataTable from '../table/DataTable'
import { getLastPatients } from '../../service/PatientService'
import { requestOK } from '../../util/requestUtil'

export default function Home() {

    const [patients, setPatients] = useState([])
    const [isFetchingPatients, setisFetchingPatients] = useState(false)

    useEffect(() => {
      getPatients()
    }, [])
    
    const getPatients = async () => {
      setisFetchingPatients(true)
      const res = await getLastPatients();
      setisFetchingPatients(false)
      if (requestOK(res)) {
        setPatients(res.data.patients)
      }
    }

  return (
    <Container  maxW='1020px' p={3} mt={6}>
         <Heading as='h4' size='md' mb={5}>
            Latest forms
        </Heading>
        <DataTable data={patients} isLoading={isFetchingPatients}></DataTable>
    </Container>
  )
}
