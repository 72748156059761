import axios from "axios";
import { BASE_URL } from "../constants/constants";

export const createNote = async (note) => {

    const token = localStorage.getItem('token');
    try {
        const res = await axios.post(`${BASE_URL}/notes`, note, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }); 
        return res;
    } catch (err) {
        return err;
    }
}
