import React, { useContext } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../../context/Auth';

const GuestRoute = ({ children }) => {
  const { authState, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (authState.isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
  
export default GuestRoute;