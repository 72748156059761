import React, { useState } from 'react'
import { Box, Center, Container, Heading, Input, Spinner, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


export default function DataTable({ data, isLoading }) {

    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
      setFilteredData(data);
    }, [data]);

    const search = (pattern) => {
        console.log(pattern)
        const newData = data.filter(item =>
            item.name.toLowerCase().includes(pattern.toLowerCase())
        );
        setFilteredData(newData);
    } 

  return (
    <div>
      <TableContainer>
            <Box>
                <Input placeholder='Search patients by name' size='sm' onChange={(e) => search(e.target.value)}/>
            </Box>
            <Table variant='simple' fontSize={{ base: 'xs',  md: 'md'}}>
                <TableCaption>Latest patients to send form</TableCaption>
                <Thead>
                <Tr>
                    <Th>Name</Th>
                    <Th>Phone</Th>
                    <Th></Th>
                </Tr>
                </Thead>
                { isLoading && <Center><Spinner color='brand.500' size='md' /></Center> }
                <Tbody>
                    {
                    filteredData && 
                        filteredData.map(d => (<Tr key={d.id}>
                            <Td>{d.name}</Td>
                            <Td>{d.phone_number}</Td>
                            <Td color='brand.500'><Link  to={`${d.id}`}>View details</Link></Td>
                        </Tr>))
                    }
                </Tbody>
            </Table>
            </TableContainer>
    </div>
  )
}
