import { Container } from '@chakra-ui/react'
import React from 'react'
import Navbar from './components/Navbar'
import Home from './home/Home'

export default function Dashboard() {
  return (
    <>
      <Navbar></Navbar>
      <Home></Home>
    </>
  )
}
