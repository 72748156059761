import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import {
  Box,
  Container,
  Stack,
  Text,
  VStack,
  Button,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem,
  HStack,
  Link,
  Icon,
  ListIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Textarea,
  Divider,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { getPatient } from '../../service/PatientService';
import { useParams } from 'react-router-dom';
import { requestHasError, requestOK } from '../../util/requestUtil';
import { ReactComponent as WhatsAppIcon } from '../../assets/icons/iconwhatsapp.svg';
import { CheckCircleIcon } from '@chakra-ui/icons'
import { createNote } from '../../service/NoteService';
import moment from 'moment';
import '../../assets/custom.css';
import { ChatIcon } from '@chakra-ui/icons'


export default function PatientView() {
  const { id } = useParams();
  const [patient, setPatient] = useState(null)
  const [note, setNote] = useState("")
  const [showNewProgressNote, setShowNewProgressNote] = useState(false)
  const [sendingProgressNote, setSendingProgressNote] = useState(false)
  const [formErrors, setFormErrors] = useState([])


  useEffect(() => {
    fetchPatient()
  }, []);

  const fetchPatient = async () => {
    const res = await getPatient(id)
    if (requestOK(res)) {
      if (res.data.patient) {
        setPatient(res.data.patient)
      }
    }
    console.log(res);
  }

  const createNewNote = async () => {
    setFormErrors([])
    setSendingProgressNote(true)
    const data = {patient_id:id, doctor_id:1, note}
    const res = await createNote(data)
    console.log(res)
 
    if (requestOK(res)) {
      setNote("");
      setShowNewProgressNote(false)
      await fetchPatient();
    }

    if (requestHasError(res)) {
      setFormErrors(res.response.data)
    }

    setSendingProgressNote(false)

  }

  return (
    <>
      <Navbar></Navbar>
      <Container maxW={'7xl'}>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 14 }}>
       
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={'header'}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
              {patient && patient.name}
            </Heading>
            <HStack>
            <Text
              color={useColorModeValue('gray.900', 'gray.400')}
              fontWeight={300}
              fontSize={'md'}>
              Contact Number: {patient && patient.phone_number}
            </Text>
            { patient && patient.phone_number &&  <Link
                href={`https://wa.me/+44${patient.phone_number}?text=Hello ${patient.name}`}
                isExternal
                display="flex"
                alignItems="center"
                color="teal.500"
                _hover={{ textDecoration: 'underline' }}
              >
                  <WhatsAppIcon  width="24" height="24" style={{ marginRight: '6px' }}/>
              </Link>}
            </HStack>
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={
              <StackDivider borderColor={useColorModeValue('gray.200', 'gray.600')} />
            }>
            <Box>
            <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={'brand.500'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                 Reason of visit
              </Text>
                <Text fontSize={'sm'}>
                  {patient && patient.reason_visit} </Text>
            </Box>

             <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={'brand.500'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                Patient information
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Sex:
                  </Text>{' '}
                  {patient && patient.sex === 'M' ? 'Male' : 'Female'}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Address:
                  </Text>{' '}
                  {patient && patient.address}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  DOB:
                  </Text>{' '}
                  {patient && patient.dob}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  Sport/ fitness:
                  </Text>{' '}
                  {patient && patient.sport ? patient.sport : 'None'}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                  GP:
                  </Text>{' '}
                  {patient && patient.gp ? patient.gp : 'None'}
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    Medication:
                  </Text>{' '}
                  {patient && patient.medication ? patient.medication : 'None'}
                </ListItem>
              </List>
            </Box>
            
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={'brand.500'}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                Contraindications
              </Text>

              { patient && patient.contraindications && patient.contraindications.length ? (
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  { patient.contraindications.map(c => <ListItem key={c.id}>
                    <ListIcon as={CheckCircleIcon} color='brand.500' />
                      {c.name}
                  </ListItem> ) }
                </List>
              </SimpleGrid>
              ) : <Text>None</Text>}
            </Box>
           
          </Stack>

          <Divider></Divider>

          <Text
            fontSize={{ base: '16px', lg: '18px' }}
            color={'brand.500'}
            fontWeight={'500'}
            textTransform={'uppercase'}
            >
            Progress notes
          </Text>

          {
            !showNewProgressNote && (
              <Button
            rounded={'none'}
            onClick={() => setShowNewProgressNote(true)}
            w={'full'}
            mt={1}
            size={'md'}
            mb={2}
            bg={'brand.500'}
            color={'white'}
            textTransform={'uppercase'}
            _hover={{
              transform: 'translateY(2px)',
              boxShadow: 'lg',
            }}>
            Add new progress note
          </Button>
            )
          }

          {
            showNewProgressNote && (
              <Box>
                <Textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder='Type the progress note'
                  size='sm'
                  minH={150}
                />
                <Text fontSize="sm" color="red">{formErrors.note}</Text>

              <Button
              isLoading={sendingProgressNote}
                  onClick={createNewNote}
                  rounded={'none'}
                  w={'full'}
                  size={'md'}
                  mt={4}
                  mb={2}
                  bg={'brand.500'}
                  color={'white'}
                  textTransform={'uppercase'}
                  _hover={{
                    transform: 'translateY(2px)',
                    boxShadow: 'lg',
                  }}>
                  Save progress note
                </Button>
                </Box>
            )
          }
         
          {
            patient && patient.notes && patient.notes.length && (
              <Accordion allowMultiple>
                {
                  patient.notes.map(n => (
                    <AccordionItem key={n.id}>
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left'>
                            <ChatIcon></ChatIcon> Note written {moment(n.created_at).fromNow()}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} className='note-container' color='gray.600'>
                        {n.note}
                      </AccordionPanel>
                    </AccordionItem>
                  ))
                }
              </Accordion>
            )
          }

        </Stack>
      </SimpleGrid>
    </Container>
    </>
  )
}
