import axios from "axios";
import { BASE_URL } from "../constants/constants";

export const registerPatient = async (formData) => {
    try {
        const res = await axios.post(`${BASE_URL}/consultancy_form`, formData);
        return res;
    } catch (err) {
        return err;
    }
}