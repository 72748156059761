import './assets/custom.css';
import React, { useContext } from 'react';

import {
  ChakraProvider,
  Container,
  extendTheme,
} from '@chakra-ui/react';
import ConsultancyForm from './components/form/ConsultancyForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import Login from './dashboard/auth/Login';
import AuthContext, { AuthProvider } from './context/Auth';
import ProtectedRoute from './components/auth/ProtectedRoute';
import GuestRoute from './components/auth/GuestRoute';
import PatientView from './dashboard/views/PatientView';

const theme = extendTheme({
  colors: {
    brand: {
      100: "#BF4073",
      200: "#BF4073",
      300: "#BF4073",
      400: "#BF4073",
      500: "#BF4073",
      600: "#BF4073",
      700: "#BF4073",
      800: "#BF4073",
      900: "#BF4073",
    },
  },
})

function App() {

  return (
      <Router>
        <ChakraProvider theme={theme}>
            <Routes>
              <Route path="/" element={<ConsultancyForm />} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>} />
              <Route path="/dashboard/:id" element={<ProtectedRoute><PatientView/></ProtectedRoute>} />
              <Route path="/login" element={<GuestRoute><Login /></GuestRoute>} />
            </Routes>
        </ChakraProvider>
      </Router>
  );
}

export default App;
