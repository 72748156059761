import React, { useContext, useState } from 'react'

import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
  } from '@chakra-ui/react'
import AuthContext from '../../context/Auth';
import { authError, requestHasError } from '../../util/requestUtil';
  
  export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [formErrors, setFormErrors] = useState([])
    const [sendingForm, setSendingForm] = useState(false)

		const { login } = useContext(AuthContext);

    const toast = useToast();

		const auth = async () => {
        setSendingForm(true);
				const data = {email, password}
				const res = await login(data)
        setSendingForm(false);

        if (requestHasError(res)) {
          setFormErrors(res.response.data)
        } 

        if(authError(res)) {
          toast({ title: 'Invalid credentials', description: "Please enter valid credentials",	status: 'error',	duration: 6000, isClosable: true })
        }
    
		}

    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading  color={'pink.400'}>Sign in to your account</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>Hi, enter your credentials</Text>
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={formErrors.email}>
                <FormLabel>Email address</FormLabel>
                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={formErrors.password}>
                <FormLabel>Password</FormLabel>
                <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                <FormErrorMessage>{formErrors.password}</FormErrorMessage>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  isLoading={sendingForm}
                  onClick={auth}
                  bg={'pink.500'}
                  color={'white'}
                  _hover={{
                    bg: 'pink.600',
                  }}>
                  Sign in
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    )
  }
