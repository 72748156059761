import axios from "axios";
import { BASE_URL } from "../constants/constants";

export const getLastPatients = async () => {

    const token = localStorage.getItem('token');
    try {
        const res = await axios.get(`${BASE_URL}/get_all_patients`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res;            
    } catch (err) {
        return err;
    }
}

export const getPatient = async (id) => {

    const token = localStorage.getItem('token');
    try {
        const res = await axios.get(`${BASE_URL}/get_patient/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res;            
    } catch (err) {
        return err;
    }
}