import axios from "axios";
import { BASE_URL } from "../constants/constants";

export const authenticate = async (formData) => {
    try {
        const res = await axios.post(`${BASE_URL}/login`, formData);
        return res;            
    } catch (err) {
        console.log("ERROR", err)
        return err;
    }
}

export const validateToken = async (token) => {
    try {
        const res = await axios.post(`${BASE_URL}/user_profile`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return res;            
    } catch (err) {
        return err;
    }
}