import {
    Box,
    Flex,
    Avatar,
    HStack,
    Text,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    Image,
  } from '@chakra-ui/react'
  import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import AuthContext from '../../context/Auth'
import { useContext } from 'react'
  
  const Links = ['Dashboard', 'All patients']
  
  const NavLink = (props) => {
    const { children } = props
    return (
      <Box
        as="a"
        px={2}
        py={1}
        rounded={'md'}
        _hover={{
          textDecoration: 'none',
          bg: useColorModeValue('gray.200', 'gray.700'),
        }}
        href={'#'}>
        {children}
      </Box>
    )
  }
  
  export default function WithAction() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const { logout } = useContext(AuthContext);

  
    return (
      <>
        <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                {Links.map((link) => (
                  <Link key={link} to="/dashboard">{link}</Link>
                ))}
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  cursor={'pointer'}
                  minW={0}>
                 Emma Bexon
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={()=>logout()}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
  
          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map((link) => (
                  <Link key={link} to="/dashboard">{link}</Link>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      </>
    )
  }