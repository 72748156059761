import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { authenticate, validateToken } from '../service/AuthService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    token: null
  });

  const [loading, setLoading] = useState(true); 

  const login = async (data) => {
    try {
      const res = await authenticate(data);
      if (res && res.status && res.status === 200) {
        const { access_token } = res.data;
				localStorage.setItem('token', access_token);
				setAuthState({ isAuthenticated: true, token: access_token });
      }
			return res;
    } catch (error) {
      console.error('Login failed', error);
      return error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthState({ isAuthenticated: false, token: null });
  };

  useEffect(() => {
		const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const res = await validateToken(token)
        if (res && res.status && res.status === 200) {
          setAuthState({ isAuthenticated: true, token });
        }
      }
      setLoading(false); // Set loading to false once the check is done
    };

    checkAuth();
  }, []);

  return (
    <AuthContext.Provider value={{ authState, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;