import { Box, Button, Text } from '@chakra-ui/react'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'

const Sign = forwardRef(({ setSign, title, buttonText }, ref) => {

    const sigPad = useRef(null);

    const [showSignButton, setshowSignButton] = useState(true);
	const [showSignControls, setShowSignControls] = useState(false);

    useEffect(() => {
		if (sigPad.current) {
			sigPad.current.off();
		}
	}, [sigPad]);

    useImperativeHandle(ref, () => ({
        resetCanvas() {
            sigPad.current.clear();
            setshowSignButton(true);
            setShowSignControls(false);
        }
      }));


    const getSign = (e) => {
		setShowSignControls(true);
		sigPad.current.off();
	}

	const enableSignForm = () => {
		if (sigPad.current) {
			sigPad.current.on();
			setshowSignButton(false);
		}
	}

	const savePatientSign = () => {
		const signData = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
		sigPad.current.off();
		setShowSignControls(false);
		setSign(signData)
	}

	const reSignForm= () => {
		sigPad.current.clear();
		sigPad.current.on();
		setShowSignControls(false);
	}


    return (
        <Box borderRadius="md" className='div-sign-box'>
            <Text mb="5px">{title}</Text>
            <ReactSignatureCanvas penColor='black' backgroundColor='#eee' onEnd={getSign} ref={sigPad} disabled
                canvasProps={{ className: 'sigCanvas'}} />
            { showSignButton && <Button colorScheme='brand' size='xs' variant='outline' className='sign-form-button' onClick={enableSignForm}>
                {buttonText}
            </Button> }
            { showSignControls &&
                <Box className='sign-controls'>
                    <Button colorScheme='brand' size='xs' variant='solid' onClick={savePatientSign}>
                        OK
                    </Button>
                    <Button ml={2} colorScheme='brand' size='xs' variant='solid'  onClick={reSignForm}>
                        Sign form again
                    </Button>
                </Box>
            }
        </Box>
    )

});

export default Sign;
